import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {reduxForm} from "redux-form";
import * as dashboardAction from "../../../../../actions/dashboardAction";
import * as uiAction from "../../../../../actions/uiAction";
import * as caseAction from "../../../../../actions/caseAction";
import FormValidator from "../../../../common/formValidator";
import Header from "../../../../common/header";
import SubHeaderForSetting from "../../../../common/subHeaderUnderSetting";
import Icon from "../../../../common/icon";
import ValidateOrRefreshToken from "../../../../common/validateOrRefreshToken";
import {bottomCenter} from "../../../../common/customToastr";
import Utility from "../../../../../api/utilLanguage";
import SearchComponent from "../../../../common/searchComponent";
import NotificationComponent from "../../../../common/notificationComponent";
import Popup from "../../../../common/popups/popup";
import ValidationUtil from "../../../../common/validationUtil";
import AppUtil from "../../../../common/appUtil";
import Button from "../../../../common/button";
import {getFenixUserId} from "../../../../common/localStorageUtil";
import {UserProps, UserUtil} from "../../../../user/userUtil";
import RolesView from "../rolesView";
import {Avatar, BackArrow, CultureDropdown} from "../utils";
import VendorContainer from "./vendorContainer";
import readFile from "../../../../case/memorialPage/fileUtil";
import UserChangeListener from "../../../userChangeListener";

class AddUserPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.roles = [];
        this.imageChanged = false;
        this.cultures = Utility.culture;
    }

    componentDidMount() {
        this.props.dashboardAction.changeUserDetailsFromSettings(UserUtil.defaultLang(this.props.selectedUser), UserProps.culture);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if ((this.props.selectedUser.culture !== nextProps.selectedUser.culture)) {
            this.props.dashboardAction.changeUserDetailsFromSettings(UserUtil.defaultLang(nextProps.selectedUser), UserProps.culture);
        }
    };

    componentDidUpdate = () => {
        if (this.props.initialSelectedUser.id !== undefined && this.props.selectedUser.id !== undefined) {
            if (JSON.stringify(this.props.initialSelectedUser) === JSON.stringify(this.props.selectedUser)) {
                console.log("Same");
                this.props.actionAdminLogin.hideUnsavedPopupLazily();
            } else {
                this.props.actionAdminLogin.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    loadPageData() {
        this.props.dashboardAction.getAllRole().then(() => {
            let id = this.props.history.location.pathname.substring(this.props.history.location.pathname.lastIndexOf('/') + 1);
            if (isNaN(id) === false) {
                this.props.dashboardAction.getUserDataByIdApi(id).finally();//.vendorId is available
            }
        });
    }

    onImageChange = (event) => {
        if (event.target !== undefined) {

            this.imageChanged = true;
            const files = event.target.files;
            const file = event.target.files[0];
            this.setState({formdataUpload: file});

            if (files && (files?.length > 0)) {
                const file = files[0];
                readFile(file).then(dataInBase64 => {
                    this.validateAndUpdateImage(dataInBase64);
                }).catch(error => {
                    bottomCenter().error(error);
                })
            }
            this.FormData = new FormData();
            this.FormData.append('file', file);
        } else {
            this.imageChanged = false;
        }
    };

    validateAndUpdateImage(dataInBase64) {
        let imageValidation = true;
        let image = new Image();
        image.src = dataInBase64;
        image.onload = () => {
            console.log("Height = ", image.height, "Width = ", image.width, "Size (MB) = ", AppUtil.closestFileSizeInMB(image.src.length));
            const validationMessages = ValidationUtil.validateImageRequirements(image.height, image.width);
            if (validationMessages.length > 0) {
                validationMessages.forEach(function (validationMessage) {
                    bottomCenter().warning(validationMessage);
                });
                imageValidation = false;
                return
            }
        };
        setTimeout(() => {
            if (imageValidation === true) {
                this.props.dashboardAction.updatedImageUser(dataInBase64);
            }
        }, 500);
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    goBack = () => {
        this.props.history.push(AppUtil.linkPaths.settings.users)
    };

    handleChange = (id) => {
        this.props.dashboardAction.changeCheckboxValue(id);
    };

    uploadUserImageApi = (file, userId) => {
        if (this.imageChanged === true) {
            setTimeout(function () {
                this.props.dashboardAction.uploadUserImageApi(file, userId);
            }.bind(this), 0);
            this.imageChanged = false;
        }
    };

    createOrUpdateUser = () => {
        this.uploadUserImageApi(this.FormData, this.props.selectedUser.id);

        const reqUser = this.getUserRequest(this.props.selectedUser);

        const status = this.hasValidData(reqUser);
        if (status) {
            if (reqUser.id === 0) {
                this.props.dashboardAction.createUserApi(reqUser);
            } else {
                const fenixUserId = getFenixUserId();
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                setTimeout(function () {
                    this.props.dashboardAction.updateUserInfoApi(reqUser, fenixUserId);
                    setTimeout(function () {
                        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                    }.bind(this), 1000)
                }.bind(this), 0);
            }
        } else {
            console.log("[Debug]:: Failed to call update or create api call");
        }
    };

    hasValidData = (user) => {
        const reg = /(.+)@(.+){2,}\.(.+){2,}/;
        let status = true;
        if (reg.test(user.email) === false) {
            bottomCenter().warning(Utility.getLang().errorMessages.user.invalidEmailAddress);
            status = false;
        }
        if (AppUtil.isAvailable(user.externalEmail) && reg.test(user.externalEmail) === false) {
            bottomCenter().warning(Utility.getLang().errorMessages.user.invalidEmailAddress);
            status = false;
        }
        if (AppUtil.isEmpty(user.roles) || user.roles.length === 0) {
            bottomCenter().error(Utility.getLang().errorMessages.user.selectUserRole);
            status = false;
        }
        return status;
    };

    getUserRequest(user) {
        return {
            "id": user.id !== undefined ? user.id : 0,
            "name": user.name,
            "email": user.email,
            "externalEmail": user.externalEmail,
            "initials": user.initials,
            "isactive": user.isactive,
            "culture": user.culture,
            "phonenumber": user.phonenumber,
            "roles": (user.roles) !== undefined ? user.roles : [],
            [UserProps.vendorId]: user.vendorId
        };
    }

    /* creating user with cognito start

            doRegister = (values) => {
                console.log("values", values);
                this.initials = values.initials;
                this.isactive = values.isactive;
                this.phonenumber = values.phone_number;
                for (let j = 0; j < this.props.allRoleData.length; j++) {
                    for (var key in values) {
                        if (values.hasOwnProperty(key)) {
                            if (key === this.props.allRoleData[j].name) {
                                this.roles.push(this.props.allRoleData[j].id)
                            }
                            console.log(key + " -> " + values[key]);
                        }
                    }
                }
                this.props.actionAdminLogin.registerUser(values);

            };

        creating user with cognito End  */

    changeUserDetailsInfo = (value, fieldName) => {
        this.props.dashboardAction.changeUserDetailsFromSettings(value, fieldName);
    };

    toMoveNewRoute = () => {
        this.props.actionAdminLogin.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        } else {
            if (this.props.subheaderMenuInMyAccount !== this.props.history.location.pathname) {
                this.props.history.push(this.props.subheaderMenuInMyAccount);
            }
        }
    };

    currentRoute = () => {
        this.props.actionAdminLogin.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionAdminLogin.subHeaderItemClickedMyAccount(this.props.history.location.pathname);
        this.props.actionAdminLogin.closePopupWithSameRoute();
    };


    render() {
        const {handleSubmit, selectedUser, hasUserChanged, initialSelectedUser} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        // console.log("[Debug]:: selected user = ", selectedUser);
        return (
            <React.Fragment>
                <UserChangeListener selectedUser={selectedUser} initialSelectedUser={initialSelectedUser}/>
                <ValidateOrRefreshToken
                    onFailure={AddUserPage.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                        <div className="panel panel--workarea panel--settings panel--users panel--user">
                            <Header/>
                            <div className="panel__content panel__content--main">
                                <SearchComponent/>
                                <main className="columns columns--static" ref={node => this.node = node}>
                                    <SubHeaderForSetting/>
                                    <div className="section__content">
                                        <div className="inner">
                                            <div className="section__columns">
                                                <div className="section__column">
                                                    <BackArrow onClick={this.goBack}/>
                                                    <h3 className="section__title">
                                                        {(selectedUser.id === null || selectedUser.id === undefined)
                                                            ? Utility.getLang().settings.users.add_user_form.add_user
                                                            : Utility.getLang().settings.users.add_user_form.edit_user}
                                                    </h3>
                                                    <form onSubmit={handleSubmit(this.createOrUpdateUser)}>
                                                        <div className="form-group">
                                                            <label>{Utility.getLang().settings.users.add_user_form.email}</label>
                                                            <input
                                                                value={selectedUser.email || ""}
                                                                onChange={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.email)}
                                                                placeholder={Utility.getLang().settings.users.add_user_form.email}
                                                                disabled={selectedUser.id === undefined ? false : true}
                                                                className="text text--max_width"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{Utility.getLang().settings.users.add_user_form.name}</label>
                                                            <input
                                                                defaultValue={selectedUser.name || ""}
                                                                onBlur={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.name)}
                                                                placeholder={Utility.getLang().settings.users.add_user_form.name}
                                                                className="text text--max_width"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{Utility.getLang().settings.users.add_user_form.externalEmail}</label>
                                                            <input
                                                                value={selectedUser.externalEmail || ""}
                                                                onChange={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.externalEmail)}
                                                                placeholder={Utility.getLang().settings.users.add_user_form.externalEmail}
                                                                className="text text--max_width"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="initials">{Utility.getLang().settings.users.add_user_form.initials}</label>
                                                            <input
                                                                onChange={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.initials)}
                                                                value={selectedUser.initials || ""}
                                                                className="text text--initials"
                                                                type="text"
                                                                maxLength="2"
                                                            />
                                                            <p className="help_text">
                                                                {Utility.getLang().settings.users.add_user_form.two_characters_displayed}
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{Utility.getLang().settings.users.add_user_form.phone_number}</label>
                                                            <input
                                                                name="phonenumber"
                                                                onBlur={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.phonenumber)}
                                                                defaultValue={selectedUser.phonenumber}
                                                                placeholder={Utility.getLang().settings.users.add_user_form.phone_number}
                                                                className="text text--max_width text--number"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <CultureDropdown
                                                                onChange={(e) => this.changeUserDetailsInfo(e.target?.value, UserProps.culture)}
                                                                culture={selectedUser.culture}
                                                                options={this.cultures}
                                                            />
                                                        </div>
                                                        <div className="form-group form-group--columns">
                                                            <div className="form-group__column">
                                                                <div className="option option--checkbox">
                                                                    <input
                                                                        id="isactive"
                                                                        checked={selectedUser.isactive || false}
                                                                        onChange={(e) => this.changeUserDetailsInfo(e.target?.checked, UserProps.isactive)}
                                                                        type="checkbox"
                                                                    />
                                                                    <label
                                                                        htmlFor="isactive"
                                                                        className="option__description option__description--checkbox"
                                                                    >
                                                                        {Utility.getLang().settings.users.add_user_form.enable_user}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group__column">
                                                                <RolesView
                                                                    roles={this.props.allRoleDataWithSelectedUserInfo}
                                                                    handleChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="btn-toolbar">
                                                            <Button
                                                                ref={handleEnterKeyPress}
                                                                isPrimary
                                                                type="submit"
                                                                disabled={hasUserChanged === false}
                                                            >
                                                                {selectedUser.id !== undefined
                                                                    ? Utility.getLang().settings.users.add_user_form.update_user
                                                                    : Utility.getLang().settings.users.add_user_form.add_user
                                                                }
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                                {
                                                    <div className="section__column">
                                                        <div className="form-group">
                                                            {
                                                                selectedUser.id !== undefined
                                                                    ? <Avatar
                                                                        url={selectedUser.profileimageurl}
                                                                        onChange={this.onImageChange}
                                                                    />
                                                                    : <Icon type="placeholder" large/>
                                                            }
                                                        </div>
                                                        <VendorContainer selectedVendorId={selectedUser.vendorId}
                                                                         onSelectionChange={this.changeUserDetailsInfo}

                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </main>
                                <NotificationComponent/>
                            </div>
                            <Popup
                                openModal={this.props.showUnsavedPopup}
                                headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                                onConfirmation={this.toMoveNewRoute}
                                closePopup={this.currentRoute}
                            />
                        </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedUser,
        selectedUser: state.application.selectedUser,
        initialSelectedUser: state.application.initialSelectedUser,
        hasUserChanged: state.application.hasUserChanged,
        hideVisibility: state.application.hideVisibility,
        allRoleData: state.application.allRoleData,
        allRoleDataWithSelectedUserInfo: state.application.allRoleDataWithSelectedUserInfo,
        userStatus: state.application.userStatus,
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionAdminLogin: bindActionCreators(uiAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "addUserPage",
    validate: new FormValidator({
        name: {presence: true},
        email: {presence: true},
        phonenumber: {presence: true},
        initials: {presence: true}
    })
})(AddUserPage));
