import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as dashboardAction from "../../actions/dashboardAction";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {Link, withRouter} from "react-router-dom";
import Header from "../common/header";
import SubHeaderForAccount from "../common/subHeaderUnderMyAccount";
import ValidateOrRefreshToken from "../common/validateOrRefreshToken";
import {reduxForm} from "redux-form";
import Popup from "../common/popups/popup";
import Utility from "../../api/utilLanguage";
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import {bottomCenter} from "../common/customToastr";
import ValidationUtil from "../common/validationUtil";
import {getFenixUserId} from "../common/localStorageUtil";
import AppUtil from "../common/appUtil";
import Icon from "../common/icon";
import Button from "../common/button";

class MyAccount extends React.Component {

    constructor(props) {
        super(props);
        this.imageChanged = false;
        this.buttomStatus = true;
        this.imageUrl = null;
        this.userId = '';
    }

    UNSAFE_componentWillMount = () => {
        this.userId = getFenixUserId();
    };

    loadPageData() {
        if (AppUtil.isAvailable(this.userId)) {
            this.props.dashboardAction.getAccountDetails(this.userId);
        } else {
            console.log("Failure: getAccountDetails, Fenix user id:", this.userId);
        }
    }

    componentDidUpdate = () => {
        if (this.props.backUpAuthUser.id !== undefined && this.props.selectedAuthUser.id !== undefined) {
            if (JSON.stringify(this.props.backUpAuthUser) === JSON.stringify(this.props.selectedAuthUser)) {
                console.log("Same");
                this.buttomStatus = true;
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.buttomStatus = false;
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    onImageChange(event) {
        if (event.target !== undefined) {
            this.imageChanged = true;
            let file = event.target.files[0];
            this.setState({formdataUpload: event.target.files[0]});
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    let imageValidation = true;
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        var validationMessages = ValidationUtil.validateImageRequirements(this.height, this.width);
                        if (validationMessages.length > 0) {
                            validationMessages.forEach(function (validationMessage) {
                                bottomCenter().warning(validationMessage);
                            });
                            imageValidation = false;
                            return
                        }
                    };
                    setTimeout(() => {
                        if (imageValidation === true) {
                            this.imageUrl = e.target.result;
                            this.props.dashboardAction.updatedImageUser(e.target.result);
                        }
                    }, 500);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            this.FormData = new FormData();
            this.FormData.append('file', file);
        } else {
            this.imageChanged = false;
        }
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    doChange = () => {
        if (this.imageChanged === true) {
            let _this = this;
            setTimeout(function () {
                this.props.dashboardAction.uploadUserImageApi(_this.FormData, this.props.selectedAuthUser.id);
            }.bind(this), 0);
            this.imageChanged = false;
        }

        let data = {
            "id": this.props.selectedAuthUser.id,
            "email": this.props.selectedAuthUser.email,
            "name": this.props.selectedAuthUser.name,
            "initials": this.props.selectedAuthUser.initials,
            "isactive": this.props.selectedAuthUser.isactive,
            "phonenumber": this.props.selectedAuthUser.phonenumber,
            "culture": this.props.selectedAuthUser.culture,
            "roles": this.props.selectedAuthUser.roles,
            "idaTheme": this.props.selectedAuthUser.idaTheme,
            "externalEmail": this.props.selectedAuthUser.externalEmail
        };
        let emailCheck = ValidationUtil.validateUserEmail(this.props.selectedAuthUser.externalEmail);
        if (this.props.selectedAuthUser.externalEmail !== "" && this.props.selectedAuthUser.externalEmail !== null && emailCheck.length > 0) {
            emailCheck.forEach(function (message) {
                bottomCenter().warning(message);
            });
            return;
        } else {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            this.props.dashboardAction.updateSelfUserInfoAndRefreshToken(data);
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);

        }
    };

    gotoResetPassword = () => {
        this.props.history.push('resetPasswordPage');
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        } else {
            if (this.props.subheaderMenuInMyAccount !== this.props.history.location.pathname) {
                this.props.history.push(this.props.subheaderMenuInMyAccount);
            }
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.subHeaderItemClickedMyAccount(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    changeUserDetails = (e, fieldName) => {
        switch (fieldName) {
            case 'userName':
                this.props.dashboardAction.changeUserInfo(e.target.value, fieldName);
                break;
            case 'initials':
                this.props.dashboardAction.changeUserInfo(e.target.value, fieldName);
                break;
            case 'phonenumber':
                this.props.dashboardAction.changeUserInfo(e.target.value, fieldName);
                break;
            case 'externalEmail':
                this.props.dashboardAction.changeUserInfo(e.target.value, fieldName);
                break;
            default: // Do Nothing
        }
    };

    render() {
        const {handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        console.log("selectedAuthUser", this.props.selectedAuthUser);
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={MyAccount.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--account panel--account_profile">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForAccount/>
                                <div className="section__content">
                                    <div className="inner">
                                        <form onSubmit={handleSubmit(this.doChange)}>
                                            <div className="section__columns">
                                                <div className="section__column">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="email">{Utility.getLang().myAccount.profile.email}</label>
                                                        <input
                                                            name="email"
                                                            defaultValue={this.props.selectedAuthUser.email}
                                                            disabled={true}
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="name">{Utility.getLang().myAccount.profile.name}</label>
                                                        <input
                                                            name="name"
                                                            onBlur={(e) => this.changeUserDetails(e, 'userName')}
                                                            className="text text--max_width"
                                                            defaultValue={this.props.selectedAuthUser.name}
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="externalEmail">{Utility.getLang().myAccount.profile.externalEmail}
                                                        </label>
                                                        <input
                                                            name="externalEmail"
                                                            onBlur={(e) => this.changeUserDetails(e, 'externalEmail')}
                                                            defaultValue={this.props.selectedAuthUser.externalEmail}
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="initials">{Utility.getLang().myAccount.profile.initials}</label>
                                                        <input
                                                            name="initials"
                                                            onChange={(e) => this.changeUserDetails(e, 'initials')}
                                                            defaultValue={this.props.selectedAuthUser.initials}
                                                            className="text text--initials"
                                                            type="text"
                                                            maxLength="2" // doesn't actually work
                                                        />
                                                        <p className="help_text">
                                                            {Utility.getLang().myAccount.profile.two_characters}
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="phonenumber">{Utility.getLang().myAccount.profile.phone_number}</label>
                                                        <input
                                                            name="phonenumber"
                                                            onBlur={(e) => this.changeUserDetails(e, 'phonenumber')}
                                                            defaultValue={this.props.selectedAuthUser.phonenumber}
                                                            className="text text--number text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="help_text">
                                                            {Utility.getLang().myAccount.profile.go_to}
                                                            <Link to={AppUtil.linkPaths.myAccount.security}>
                                                                {Utility.getLang().myAccount.profile.security}
                                                            </Link>
                                                            {Utility.getLang().myAccount.profile.to_change_your_password}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="section__column">
                                                    <div className="form-group">
                                                        <div className="current_avatar">
                                                            <div className="current_avatar__outline">
                                                                {this.props.selectedAuthUser.profileimageurl !== null ?
                                                                    <img
                                                                        src={this.props.selectedAuthUser.profileimageurl}
                                                                        width="96"
                                                                        height="96"
                                                                        alt={Utility.getLang().common.myAvatar}
                                                                    />
                                                                    :
                                                                    <Icon type="avatar" huge/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <label htmlFor="accountProfilePicture">
                                                            {Utility.getLang().myAccount.profile.update_profile_picture}
                                                        </label>
                                                        <input
                                                            id="accountProfilePicture"
                                                            name="accountProfilePicture"
                                                            className="text file"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={this.onImageChange.bind(this)}
                                                        />
                                                        <p className="help_text">
                                                            {Utility.getLang().myAccount.profile.square_jpg_png}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section__toolbar btn-toolbar">
                                                <Button
                                                    ref={handleEnterKeyPress}
                                                    isPrimary
                                                    type="submit"
                                                    disabled={this.buttomStatus}
                                                    id="accountProfileSave"
                                                >
                                                    {Utility.getLang().myAccount.profile.save_profile}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                        <Popup
                            openModal={this.props.showUnsavedPopup}
                            headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                            onConfirmation={this.toMoveNewRoute}
                            closePopup={this.currentRoute}
                        />
                    </div>
                }
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedAuthUser,
        backUpAuthUser: state.application.backUpAuthUser,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
        showUnsavedPopup: state.application.showUnsavedPopup,
        selectedAuthUser: state.application.selectedAuthUser,
        hideVisibility: state.application.hideVisibility,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "myAccountPage"
})(withRouter(MyAccount)));
