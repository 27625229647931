import Utility from "../../../api/utilLanguage";
import FieldType from "../../common/fieldType";
import DataKey from "../../common/dataKey";
import {LocationTypes} from "../../../api/searchService";
import {EventAttribute} from "./ukTenantOrderAttributes";

/**
 * @requires all Order event attributes should be mentioned in here
 * @requires selectOptions = {label and value}
 */
export default {
    [EventAttribute.ceremonyType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.CEREMONY_TYPES,
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.ceremonyUrnOrCasket]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.ceremonyMortuaryLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.crematorium, LocationTypes.coldRoom, LocationTypes.mortuary],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.ceremonyLocation],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyDate]: {
        fieldType: FieldType.DATE,
        sizeCss: "small"
    },
    [EventAttribute.ceremonyTime]: {
        fieldType: FieldType.TIME,
        sizeCss: "small"
    },
    [EventAttribute.ceremonyPall]: {
        fieldType: FieldType.CHECKBOX
    },
    [EventAttribute.ceremonyOfficiant]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyOrganist]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyRepresentative]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyCareTaker]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyFlorist]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyHandleFlowersAfterCeremony]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyUrnTransportToCeremony]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        availableOn: [
            {
                attributeType: EventAttribute.ceremonyUrnOrCasket,
                value: ["1", "2"]
            }
        ],
        disabledOptions: [
            {
                attributeType: EventAttribute.ceremonyUrnOrCasket,
                value: ["2"],
                disabledValue: ["5"]
            }
        ],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.ceremonyDressCode]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        sizeCss: "large"
    },

    [EventAttribute.entombmentType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.BURIAL_TYPES,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.graveyards],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentNewOrExistingGrave]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "55", "56", "57"]
            }
        ],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.entombmentGraveNumber]: {
        fieldType: FieldType.TEXT,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "55", "56", "57"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.entombmentDate]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "55", "56", "57"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.entombmentTime]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "55", "56", "57"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.entombmentCrematoriumLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.crematorium],
        isSearchable: true,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["4", "5", "6", "8", "9", "56", "57"]
            }
        ],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentGraveDeedHolder]: {
        fieldType: FieldType.TEXT,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentNewOrExistingGrave,
                value: ["2"]
            }
        ],
        sizeCss: "large"
    },
    [EventAttribute.entombmentUrnTransportToEntombment]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "55", "56", "57"]
            }
        ],
        disabledOptions: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["7", "10", "11", "12", "13", "14", "15", "55"],
                disabledValue: ["5"]
            }
        ],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentCaretakerForUrnBurial]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        everyAvailableOnSatisfied: true,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["4", "5", "6", "8", "9", "56", "57"]
            },
            {
                attributeType: EventAttribute.entombmentUrnTransportToEntombment,
                value: ["2", "3"]
            }
        ],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentFuneralAssociationBurial]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        everyAvailableOnSatisfied: true,
        availableOn: [
            {
                attributeType: EventAttribute.entombmentType,
                value: ["4", "5", "6", "8", "9", "56", "57"]
            },
            {
                attributeType: EventAttribute.entombmentUrnTransportToEntombment,
                value: ["2", "3"]
            }
        ],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.entombmentRelativesAttendance]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "large"
    },

    [EventAttribute.receptionLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.parishHome],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.receptionDate]: {
        fieldType: FieldType.DATE,
        sizeCss: "small"
    },
    [EventAttribute.receptionTime]: {
        fieldType: FieldType.TIME,
        sizeCss: "small"
    },
    [EventAttribute.receptionRsvpDate]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.receptionHandleRsvp,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.receptionHandleRsvp]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.receptionArrangedBy]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.receptionNumberOfGuests]: {
        fieldType: FieldType.NUMBER,
        sizeCss: "min_width"
    },
    [EventAttribute.receptionMaximumRegistrations]: {
        fieldType: FieldType.NUMBER,
        sizeCss: "medium"
    },

    [EventAttribute.preparationMeetingType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.preparationMeetingLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        availableOn: [
            {
                attributeType: EventAttribute.preparationMeetingType,
                value: ["2"]
            }
        ],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.externalOffice],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.preparationPersonalFarewell]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.preparationPersonalFarewellDate]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.preparationPersonalFarewell,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.preparationPersonalFarewellTime]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.preparationPersonalFarewell,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.preparationPersonalFarewellLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        availableOn: [
            {
                attributeType: EventAttribute.preparationPersonalFarewell,
                value: ["1"]
            }
        ],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.hospital],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.preparationFingerprint]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.preparationDnaTest]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.preparationBelongings]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.preparationTransporter]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.PR_TRANSPORTER,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.preparationMemorialFund]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.MEMORIAL_FUNDS,
        categorizedData: true,
        categorizedDataLabel: Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds,
        sizeCss: "large"
    },
    [EventAttribute.preparationMortuaryRemoval]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: [LocationTypes.crematorium],
        isSearchable: true,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.preparationMemorialPage]: {fieldType: FieldType.CHECKBOX},
    none: {fieldType: FieldType.NONE}
}
